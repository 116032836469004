import {
  GET_BILLING_ACCOUNTS_FULFILLED,
  GET_BILLING_ACCOUNTS_REJECTED
} from '../actions/getBillingAccounts';
import { SIGN_OUT_FULFILLED, SIGN_OUT_REJECTED } from '../actions/signOut';
import { GET_BILLING_ACCOUNT_FULFILLED } from '../actions/getBillingAccount';
import { GET_BILLING_ACCOUNTS_BALANCE_FULFILLED } from '../actions/getBillingAccountsBalance';
import { GET_PAYMENT_RECEIPTS_FULFILLED } from '../actions/getPaymentReceipts';
import { Receipt } from './paymentReceiptsReducer';
import { GET_DUE_DATES_FULFILLED } from '../actions/getDueDates';

export interface AutoPay {
  type: string;
  brand: string | null;
  brandName: string | null;
  digits: string | null;
  expMonth?: string;
  expYear?: string;
  withdrawalDate: string | null;
}

export interface LastPayMethod {
  type: string;
  digits: string;
  brand: string;
  brandName: string;
}

export interface BillingAccount {
  accountName: string;
  accountNumber: string;
  firstName: string;
  lastName: string;
  financialAccountId: string;
  status?: string;
  entityStatus?: string;
  billDueDate?: string;
  enrolledInEbill: boolean;
  servicePeriod?: string;
  mostRecentBillTotal?: number;
  currentBalance?: number;
  lastPaymentAmount?: number | null;
  lastPaymentDate?: string | null;
  lastPayMethod?: LastPayMethod;
  pastDueAmount?: number;
  autoPay?: AutoPay | null;
  detailedInformation: {
    myAccountBill: boolean;
    myAccountPayment: boolean;
  };
}

interface BillingAccountData {
  autoPay: {
    brand: string;
    brandName: string;
    digits: string;
    expMonth: string;
    expYear: string;
    type: string;
    withdrawalDate: string;
  } | null;
  billingArrangementId: string;
  billingArrangementName: string;
  entityStatus: string;
  financialAccountId: string;
  firstName: string;
  individualId: string;
  lastName: string;
  myAccountBill: {
    mostRecentBillAmt: number;
    mostRecentBillDueDate: string;
    nextBillDate: string;
    servicePeriodEndDate: string;
    servicePeriodStartDate: string;
  };
  myAccountPayment: {
    currentBalance: number;
    lastPaymentAmount: number;
    lastPaymentRecievedDate: string;
    paymentMethod: string;
    pastDueAmount: number;
  };
}

interface MyAccountBill {
  mostRecentBillAmt: number;
  mostRecentBillDueDate: string;
  nextBillDate: string;
  servicePeriodEndDate: string;
  servicePeriodStartDate: string;
  billingArrangementId: string;
}

export interface BillingAccountMap {
  [key: string]: BillingAccount;
}

export default function billingAccountsReducer(
  state: BillingAccountMap | null = null,
  action: any
): BillingAccountMap | null {
  switch (action.type) {
    case GET_BILLING_ACCOUNTS_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return null;
      }
      const accounts: BillingAccountMap = {};
      data.results.forEach((result: BillingAccountData) => {
        accounts[`_${result.financialAccountId}`] = {
          accountName: result.billingArrangementName,
          firstName: result.firstName,
          lastName: result.lastName,
          accountNumber: result.billingArrangementId,
          financialAccountId: result.financialAccountId,
          status:
            result.entityStatus === 'Tentative'
              ? 'Canceled'
              : result.entityStatus,
          entityStatus: result.entityStatus,
          enrolledInEbill: true,
          detailedInformation: {
            myAccountBill: false,
            myAccountPayment: false
          },
          currentBalance: result.myAccountPayment?.currentBalance,
          lastPaymentAmount: result.myAccountPayment?.lastPaymentAmount,
          lastPaymentDate: result.myAccountPayment?.lastPaymentRecievedDate,
          lastPayMethod: undefined,
          billDueDate: result.myAccountBill?.mostRecentBillDueDate,
          mostRecentBillTotal: result.myAccountBill?.mostRecentBillAmt,
          servicePeriod:
            result.myAccountBill?.servicePeriodStartDate &&
            result.myAccountBill?.servicePeriodEndDate
              ? `${result.myAccountBill?.servicePeriodStartDate} - ${result.myAccountBill?.servicePeriodEndDate}`
              : '--/--/-- - --/--/--',
          autoPay: result.autoPay
            ? {
                type: result.autoPay.type,
                brand: result.autoPay.brand,
                brandName: result.autoPay.brandName,
                digits: result.autoPay.digits,
                expMonth: result.autoPay.expMonth,
                expYear: result.autoPay.expYear,
                withdrawalDate: result.autoPay.withdrawalDate
              }
            : null
        };
      });
      return accounts;
    }
    case GET_BILLING_ACCOUNT_FULFILLED: {
      const { data } = action.payload;
      const result: BillingAccountData = data.results;
      if (state) {
        const account: BillingAccount = {
          ...state[`_${result.financialAccountId}`],
          detailedInformation: {
            ...state[`_${result.financialAccountId}`].detailedInformation,
            myAccountPayment: true
          },
          currentBalance: result.myAccountPayment?.currentBalance,
          lastPaymentAmount: result.myAccountPayment?.lastPaymentAmount,
          lastPaymentDate: result.myAccountPayment?.lastPaymentRecievedDate,
          lastPayMethod: undefined,
          pastDueAmount: result.myAccountPayment?.pastDueAmount
        };
        return {
          ...state,
          [`_${result.financialAccountId}`]: account
        };
      }
      return state;
    }

    case SIGN_OUT_REJECTED:
    case SIGN_OUT_FULFILLED:
      return null;

    case GET_BILLING_ACCOUNTS_BALANCE_FULFILLED: {
      if (state) {
        const { data } = action.payload;
        const accounts: BillingAccountMap = {};
        data.results.forEach((result: BillingAccountData) => {
          accounts[`_${result.financialAccountId}`] = {
            ...state[`_${result.financialAccountId}`],
            detailedInformation: {
              ...state[`_${result.financialAccountId}`].detailedInformation,
              myAccountPayment: true
            },
            currentBalance: result.myAccountPayment?.currentBalance,
            lastPaymentAmount: result.myAccountPayment?.lastPaymentAmount,
            lastPaymentDate: result.myAccountPayment?.lastPaymentRecievedDate,
            lastPayMethod: undefined,
            pastDueAmount: result.myAccountPayment?.pastDueAmount
          };
        });
        return {
          ...state,
          ...accounts
        };
      }
      return state;
    }
    case GET_PAYMENT_RECEIPTS_FULFILLED: {
      if (state) {
        const { data } = action.payload;
        const accounts: BillingAccountMap = {};
        data.results.forEach((result: Receipt) => {
          accounts[`_${result.financialAccountId}`] = {
            ...state[`_${result.financialAccountId}`],
            detailedInformation: {
              myAccountPayment: false,
              myAccountBill: false
            },
            currentBalance: undefined,
            lastPaymentAmount: undefined,
            lastPaymentDate: undefined,
            lastPayMethod: undefined,
            pastDueAmount: undefined
          };
        });
        return {
          ...state,
          ...accounts
        };
      }
      return state;
    }
    case GET_DUE_DATES_FULFILLED: {
      if (state) {
        const { data } = action.payload;
        const accounts: BillingAccountMap = {};
        const baConversion: { [key: string]: string } = {};
        Object.values(state).forEach(account => {
          baConversion[account.accountNumber] = account.financialAccountId;
        });
        data.results.forEach((result: MyAccountBill) => {
          accounts[`_${baConversion[result.billingArrangementId]}`] = {
            ...state[`_${baConversion[result.billingArrangementId]}`],
            detailedInformation: {
              ...state[`_${baConversion[result.billingArrangementId]}`]
                .detailedInformation,
              myAccountBill: true
            },
            billDueDate: result.mostRecentBillDueDate || '--/--/--',
            mostRecentBillTotal: result.mostRecentBillAmt,
            servicePeriod:
              result.servicePeriodStartDate && result.servicePeriodEndDate
                ? `${result.servicePeriodStartDate} - ${result.servicePeriodEndDate}`
                : '--/--/-- - --/--/--'
          };
        });
        return {
          ...state,
          ...accounts
        };
      }
      return state;
    }
    case GET_BILLING_ACCOUNTS_REJECTED: {
      return {};
    }
    default:
      return state;
  }
}

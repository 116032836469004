import axios from 'axios';
import { BillingContact } from '../components/BillingName';

export const SAVE_BILLING_PROFILE_CONTACT = 'SAVE_BILLING_PROFILE_CONTACT';
export const SAVE_BILLING_PROFILE_CONTACT_FULFILLED =
  'SAVE_BILLING_PROFILE_CONTACT_FULFILLED';
export const SAVE_BILLING_PROFILE_CONTACT_REJECTED =
  'SAVE_BILLING_PROFILE_CONTACT_REJECTED';
export const SAVE_BILLING_PROFILE_CONTACT_PENDING =
  'SAVE_BILLING_PROFILE_CONTACT_PENDING';

export function saveBillingName(
  baId: string,
  billingContact: BillingContact
): any {
  return {
    type: SAVE_BILLING_PROFILE_CONTACT,
    payload: axios.post(
      `${process.env.REACT_APP_BILLING_PROFILE_UPDATE_CONTACT_SERVICE_ENDPOINT}/${baId}`,
      billingContact
    ),
    meta: baId
  };
}

import axios from 'axios';

export const GET_BILLING_ACCOUNT = 'GET_BILLING_ACCOUNT';
export const GET_BILLING_ACCOUNT_FULFILLED = `${GET_BILLING_ACCOUNT}_FULFILLED`;
export const GET_BILLING_ACCOUNT_REJECTED = `${GET_BILLING_ACCOUNT}_REJECTED`;

export function getBillingAccount(request: string): any {
  return {
    type: GET_BILLING_ACCOUNT,
    payload: axios.get(
      `${process.env.REACT_APP_BILLING_ACCOUNTS_SERVICE_ENDPOINT}/${request}`
    )
  };
}

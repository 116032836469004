import { GET_PAYMENT_HISTORY_FULFILLED } from '../actions/getPaymentHistory';
import { SELECT_BILLING_ACCOUNT_FULFILLED } from '../actions/selectBillingAccount';
import { PaymentType } from '../components/PaymentHistory';

interface PaymentHistoryResult {
  currency: string | null;
  amount: number;
  confirmationNumber: string | null;
  originalAmount: number;
  payMeanBrand: string | null;
  payMeanID: string | null;
  paymentDate: string;
  paymentForm: string;
  paymentMethod: string | null;
}

export default function paymentHistoryReducer(
  state: PaymentType[] | null = null,
  action: any
): PaymentType[] | null {
  switch (action.type) {
    case GET_PAYMENT_HISTORY_FULFILLED: {
      const { data } = action.payload;
      if (!data.results) {
        return null;
      }
      const paymentHistory: PaymentType[] = data.results.paymentHistoryList.map(
        (payment: PaymentHistoryResult) => {
          return {
            date: payment.paymentDate,
            amount: payment.amount,
            confirmationNumber: payment.confirmationNumber || '--',
            brand: payment.payMeanBrand,
            paidWith: payment.paymentForm,
            paymentMethod: payment.paymentMethod
          };
        }
      );
      return paymentHistory;
    }
    case SELECT_BILLING_ACCOUNT_FULFILLED:
      return null;
    default:
      return state;
  }
}

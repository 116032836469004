import { DisplaySearchSuggestionAction } from '../actions/displaySearchSuggestions';

export const DISPLAY_SEARCH_SUGGESTIONS = 'DISPLAY_SEARCH_SUGGESTIONS';

export interface DisplaySearchSuggestion {
  [key: string]: string[];
}

export default function searchSuggestionReducer(
  state: DisplaySearchSuggestion = {},
  action: DisplaySearchSuggestionAction
): DisplaySearchSuggestion {
  switch (action.type) {
    case DISPLAY_SEARCH_SUGGESTIONS: {
      return {
        ...state,
        [action.payload.searchIn]: action.payload.suggestions
      };
    }
    default:
      return state;
  }
}

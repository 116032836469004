import axios from 'axios';
import { SecurityCodeResponseType } from '../types/SecurityPinType';

export const SAVE_SECURITY_CODE_INFO = 'SAVE_SECURITY_CODE_INFO';
export const SAVE_SECURITY_CODE_INFO_FULFILLED = `${SAVE_SECURITY_CODE_INFO}_FULFILLED`;
export const SAVE_SECURITY_CODE_INFO_PENDING = `${SAVE_SECURITY_CODE_INFO}_PENDING`;
export const SAVE_SECURITY_CODE_INFO_REJECTED = `${SAVE_SECURITY_CODE_INFO}_REJECTED`;

export function saveSecurityCodeInfo(
  securityCode: SecurityCodeResponseType
): any {
  return {
    type: SAVE_SECURITY_CODE_INFO,
    payload: axios.put(
      `${process.env.REACT_APP_SECURITY_CODE_SERVICE_ENDPOINT}`,
      securityCode
    )
  };
}

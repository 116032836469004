import { SIGN_OUT_FULFILLED, SIGN_OUT_REJECTED } from '../actions/signOut';
import { BillingAccount } from './billingAccountsReducer';
import {
  SELECT_BILLING_ACCOUNT_FULFILLED,
  SELECT_BILLING_ACCOUNT_REJECTED
} from '../actions/selectBillingAccount';

export default function selectedBillingAccount(
  state: BillingAccount | null = null,
  action: any
): BillingAccount | null {
  switch (action.type) {
    case SELECT_BILLING_ACCOUNT_FULFILLED:
      return action.meta;
    case SELECT_BILLING_ACCOUNT_REJECTED:
      return null;
    case SIGN_OUT_REJECTED:
    case SIGN_OUT_FULFILLED:
      return null;
    default:
      return state;
  }
}

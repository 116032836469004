export default function loadingReducer(state: number = 0, action: any) {
  if (action.type?.includes('PENDING')) {
    return state + 1;
  }
  if (action.type?.includes('FULFILLED') || action.type?.includes('REJECTED')) {
    return state > 0 ? state - 1 : 0;
  }
  if (action.type === 'FORCE_RESET_LOADER') {
    return 0;
  }
  return state;
}

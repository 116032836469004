import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['status']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(promise);
const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);
export { store, persistor };

import axios from 'axios';

export const GET_FA_IDS = 'GET_FA_IDS';
export const GET_FA_IDS_FULFILLED = `${GET_FA_IDS}_FULFILLED`;
export const GET_FA_IDS_REJECTED = `${GET_FA_IDS}_REJECTED`;

export function getFAIds(): any {
  return {
    type: GET_FA_IDS,
    payload: axios.get(`${process.env.REACT_APP_GET_FA_IDS_SERVICE_ENDPOINT}`)
  };
}

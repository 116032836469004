import axios from 'axios';

export const GET_BILLING_ACCOUNTS = 'GET_BILLING_ACCOUNTS';
export const GET_BILLING_ACCOUNTS_FULFILLED = `${GET_BILLING_ACCOUNTS}_FULFILLED`;
export const GET_BILLING_ACCOUNTS_REJECTED = `${GET_BILLING_ACCOUNTS}_REJECTED`;

export function getBillingAccounts(): any {
  return {
    type: GET_BILLING_ACCOUNTS,
    payload: axios.get(
      `${process.env.REACT_APP_BILLING_ACCOUNTS_SERVICE_ENDPOINT}?pullDetails=false`
    )
  };
}

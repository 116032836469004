var _typeof =
    typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
        ? function(obj) {
            return typeof obj;
        }
        : function(obj) {
            return obj &&
            typeof Symbol === 'function' &&
            obj.constructor === Symbol &&
            obj !== Symbol.prototype
                ? 'symbol'
                : typeof obj;
        };

/* eslint-disable */

var device,
    previousDevice,
    addClass,
    documentElement,
    find,
    handleOrientation,
    hasClass,
    orientationEvent,
    removeClass,
    userAgent,
    iphone,
    iPad;
previousDevice = window.device;
device = {};
window.device = device;
documentElement = window.document.documentElement;
userAgent = window.navigator.userAgent.toLowerCase();

device.ios = function() {
    return device.iphone() || device.ipod() || device.ipad();
};
device.windows = function() {
    return find('windows');
};
device.blackberry = function() {
    return find('blackberry') || find('bb10') || find('rim');
};
device.fxos = function() {
    return (find('(mobile;') || find('(tablet;')) && find('; rv:');
};
device.ipad = function() {
    return find('ipad');
};
device.androidTablet = function() {
    return device.android() && !find('mobile');
};
device.blackberryTablet = function() {
    return device.blackberry() && find('tablet');
};
device.windowsTablet = function() {
    return device.windows() && find('touch') && !device.windowsPhone();
};
device.fxosTablet = function() {
    return device.fxos() && find('tablet');
};
device.iphone = function() {
    return !device.windows() && find('iphone');
};
device.ipod = function() {
    return find('ipod');
};
device.androidPhone = function() {
    return device.android() && find('mobile');
};
device.android = function() {
    return !device.windows() && find('android');
};
device.blackberryPhone = function() {
    return device.blackberry() && !find('tablet');
};
device.windowsPhone = function() {
    return device.windows() && find('phone');
};
device.fxosPhone = function() {
    return device.fxos() && find('mobile');
};
device.meego = function() {
    return find('meego');
};
device.cordova = function() {
    return window.cordova && location.protocol === 'file:';
};
device.nodeWebkit = function() {
    return _typeof(window.process) === 'object';
};
device.mobile = function() {
    return (
        device.androidPhone() ||
        device.iphone() ||
        device.ipod() ||
        device.windowsPhone() ||
        device.blackberryPhone() ||
        device.fxosPhone() ||
        device.meego()
    );
};
device.tablet = function() {
    return (
        device.ipad() ||
        device.androidTablet() ||
        device.blackberryTablet() ||
        device.windowsTablet() ||
        device.fxosTablet()
    );
};
device.desktop = function() {
    return !device.tablet() && !device.mobile();
};
device.portrait = function() {
    return window.innerHeight / window.innerWidth > 1;
};
device.landscape = function() {
    return window.innerHeight / window.innerWidth < 1;
};
device.noConflict = function() {
    window.device = previousDevice;
    return this;
};
find = function find(needle) {
    return userAgent.indexOf(needle) !== -1;
};
hasClass = function hasClass(className) {
    var regex;
    regex = new RegExp(className, 'i');
    return documentElement.className.match(regex);
};
addClass = function addClass(className) {
    var currentClassNames = null;
    if (!hasClass(className)) {
        currentClassNames = documentElement.className.replace(/^\s+|\s+$/g, '');
        documentElement.className = currentClassNames + ' ' + className;
    }
};
removeClass = function removeClass(className) {
    if (hasClass(className)) {
        documentElement.className = documentElement.className.replace(
            ' ' + className,
            ''
        );
    }
};
handleOrientation = function handleOrientation() {
    if (device.landscape()) {
        removeClass('portrait');
        addClass('landscape');
    } else {
        removeClass('landscape');
        addClass('portrait');
    }
    return;
};
if (Object.prototype.hasOwnProperty.call(window, 'onorientationchange')) {
    orientationEvent = 'orientationchange';
} else {
    orientationEvent = 'resize';
}
if (window.addEventListener) {
    window.addEventListener(orientationEvent, handleOrientation, false);
} else if (window.attachEvent) {
    window.attachEvent(orientationEvent, handleOrientation);
} else {
    window[orientationEvent] = handleOrientation;
    handleOrientation();
    if (
        typeof define === 'function' &&
        _typeof(define.amd) === 'object' &&
        define.amd
    ) {
        define(function() {
            return device;
        });
    } else if (typeof module !== 'undefined' && module.exports) {
        module.exports = device;
    } else {
        window.device = device;
    }
}

var isTouchDevice = 'ontouchstart' in document.documentElement;
var desktop = device.desktop();
var mobile = device.mobile();
var tablet = device.tablet();
var windows = device.windows();
var ios = device.ios();
var android = device.android();
var blackberry = device.blackberry();
var windowsTablet = device.windowsTablet();
var androidTablet = device.androidTablet();
var blackberryTablet = device.blackberryTablet();
var ipad = device.ipad();
var iphone = device.iphone();
var windowsPhone = device.windowsPhone();
var blackberryPhone = device.blackberryPhone();
var androidPhone = device.androidPhone();
var ipod = device.ipod();
var portrait = device.portrait();
var landscape = device.landscape();

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + '; ' + expires;
}

function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) return hash;
    for (i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = (hash << 5) - hash + char;
    }
    return hash;
};

function createTimeStampNow() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yy = new Date()
        .getFullYear()
        .toString()
        .substr(2, 2);
    var hh = today.getHours();
    var min = today.getMinutes();
    var ss = today.getSeconds();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    if (hh < 10) {
        hh = '0' + hh;
    }
    if (min < 10) {
        min = '0' + min;
    }
    if (ss < 10) {
        ss = '0' + ss;
    }
    var mm = Number(mm);
    var monthCode = mm + 64;
    var monthCode = String.fromCharCode(monthCode);
    var dd = Number(dd);
    if (dd >= 0 && dd <= 26) {
        dayCode = 64 + dd;
    } else {
        dayCode = 71 + dd;
    }
    var dayCode = String.fromCharCode(dayCode);
    var yy = Number(yy);
    var yearCode = yy + 49;
    var yearCode = String.fromCharCode(yearCode);
    var hh = Number(hh);
    var hourCode = hh + 65;
    var hourCode = String.fromCharCode(hourCode);
    var min = Number(min);
    if (min >= 0 && min <= 9) {
        minCode = 48 + min;
    } else if (min >= 10 && min <= 35) {
        minCode = 55 + min;
    } else {
        minCode = 61 + min;
    }
    var minCode = String.fromCharCode(minCode);
    var ss = Number(ss);
    if (ss >= 0 && ss <= 9) {
        secCode = 48 + ss;
    } else if (ss >= 10 && ss <= 35) {
        secCode = 55 + ss;
    } else {
        secCode = 61 + ss;
    }
    var secCode = String.fromCharCode(secCode);
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    if (hh < 10) {
        hh = '0' + hh;
    }
    if (min < 10) {
        min = '0' + min;
    }
    if (ss < 10) {
        ss = '0' + ss;
    }
    var timeStampNow =
        monthCode + dayCode + yearCode + hourCode + minCode + secCode;
    return timeStampNow;
}

var oldNew = 'U';
var toDisplay = 'YMDHMS';
var newTS = createTimeStampNow();

if (getCookie('ShoppingTime') != null) {
    oldNew = 'O';
    var existingTS = getCookie('ShoppingTime');
    toDisplay = existingTS;
} else {
    setCookie('ShoppingTime', newTS, 3650);
    toDisplay == newTS;
    oldNew = 'N';
}
var shoppingID = toDisplay;
var pluginstr;
var pluginlen = navigator.plugins.length;
for (var i = 0; i < pluginlen; i++) {
    pluginstr += navigator.plugins[i].name;
}

var timz = new Date(Date.UTC(2016, 12, 30, 0, 0, 0, 0));
var tzoffset = -1 * timz.getTimezoneOffset();

var tzoffsetstr = tzoffset.toString();
if (tzoffsetstr.length == 1) {
    tzoffsetstr = '0' + tzoffsetstr;
}
if (tzoffsetstr.length == 2) {
    tzoffsetstr = '0' + tzoffsetstr;
}
if (tzoffsetstr.match(/-/)) {
} else {
    tzoffsetstr = '+' + tzoffsetstr;
}
if (tzoffsetstr == '-60') {
    tzoffsetstr = '-060';
}

var ua = navigator.userAgent;
var browserName = navigator.appName;
var brow = 'U';
var nVer = navigator.appVersion;
var fullVersion = '' + parseFloat(navigator.appVersion);
var majorVer = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;
if (
    ua.indexOf('Edge') == -1 &&
    ua.indexOf('Chromium') == -1 &&
    (verOffset = ua.indexOf('Chrome')) != -1
) {
    browserName = 'Chrome';
    fullVersion = ua.substring(verOffset + 7);
    brow = 'C';
} else if ((verOffset = ua.indexOf('OPR/')) != -1) {
    browserName = 'Opera';
    fullVersion = ua.substring(verOffset + 4);
    brow = 'O';
} else if ((verOffset = ua.indexOf('Opera')) != -1) {
    browserName = 'Opera';
    fullVersion = ua.substring(verOffset + 6);
    if ((verOffset = ua.indexOf('Version')) != -1)
        fullVersion = ua.substring(verOffset + 8);
    brow = 'O';
} else if (/*@cc_on!@*/ false || document.documentMode) {
    browserName = 'IE';
    fullVersion = ua.substring(verOffset + 5);
    brow = 'I';
} else if (
    ua.indexOf('Chrome') == -1 &&
    ua.indexOf('Chromium') == -1 &&
    (verOffset = ua.indexOf('Safari')) != -1
) {
    browserName = 'Safari';
    fullVersion = ua.substring(verOffset + 7);
    if ((verOffset = ua.indexOf('Version')) != -1)
        fullVersion = ua.substring(verOffset + 8);
    brow = 'S';
} else if (
    ua.indexOf('Seamonkey') == -1 &&
    (verOffset = ua.indexOf('Firefox')) != -1
) {
    browserName = 'Firefox';
    fullVersion = ua.substring(verOffset + 8);
    brow = 'F';
} else if ((verOffset = ua.indexOf('Edge')) != -1) {
    browserName = 'Edge';
    fullVersion = ua.substring(verOffset + 7);
    if ((verOffset = ua.indexOf('Version')) != -1)
        fullVersion = ua.substring(verOffset + 8);
    brow = 'E';
} else if ((verOffset = ua.indexOf('BB')) != -1) {
    browserName = 'Blackberry';
    fullVersion = ua.substring(verOffset + 7);
    if ((verOffset = ua.indexOf('Version')) != -1)
        fullVersion = ua.substring(verOffset + 8);
    brow = 'B';
} else if (
    (nameOffset = ua.lastIndexOf(' ') + 1) < (verOffset = ua.lastIndexOf('/'))
) {
    browserName = ua.substring(nameOffset, verOffset);
    fullVersion = ua.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
        brow = 'U';
    }
}
if ((ix = fullVersion.indexOf(';')) != -1)
    fullVersion = fullVersion.substring(0, ix);
if ((ix = fullVersion.indexOf(' ')) != -1)
    fullVersion = fullVersion.substring(0, ix);
majorVer = parseInt('' + fullVersion, 10);
if (isNaN(majorVer)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVer = parseInt(navigator.appVersion, 10);
}
var sh = screen.height;
var sw = screen.width;
var pixelRatio = window.devicePixelRatio;
var rez;
if (sh > sw) {
    rez = sh / sw;
} else {
    rez = sw / sh;
}
rez = (10 * Number(rez)).toFixed(4);
var likelyDeviceNontouch, likelyDeviceTouch, likelyDeviceDisplay;
var dev2 = 'X';
if (dev2 == 'X' && rez < 10) {
    dev2 = 'a';
    likelyDeviceNontouch = 'Unknown device';
    likelyDeviceTouch = 'Unknown device';
} else if (dev2 == 'X' && rez < 12.5) {
    dev2 = 'b';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Blackberry smartphone';
} else if (dev2 == 'X' && rez < 13.2813) {
    dev2 = 'c';
    likelyDeviceNontouch = 'Monitor/Laptop';
    likelyDeviceTouch = 'x';
} else if (dev2 == 'X' && rez < 13.3333) {
    dev2 = 'd';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Blackberry Torch smartphone';
} else if (dev2 == 'X' && rez < 13.3398) {
    dev2 = 'e';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch =
        'Blackberry smartphone; Tablet (Apple iPad 1/2/3/4, Apple iPad Mini 1/2/3; HTC Nexus 9';
} else if (dev2 == 'X' && rez < 14.0625) {
    dev2 = 'f';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Tablet (Apple iPad Pro)';
} else if (dev2 == 'X' && rez < 15.0) {
    dev2 = 'g';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Tablet (Microsoft Surface Pro 3)';
} else if (dev2 == 'X' && rez < 15.9934) {
    dev2 = 'h';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch =
        'Smartphone (Apple iPhone 3/4, HTC 8x, Microsoft Lumia, ZTE Open)';
} else if (dev2 == 'X' && rez < 16.0) {
    dev2 = 'i';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Tablet (Asus Nexus 7 v1)';
} else if (dev2 == 'X' && rez < 16.6111) {
    dev2 = 'j';
    likelyDeviceNontouch = 'Monitor/Laptop';
    likelyDeviceTouch =
        'Samsung Galaxy Note smartphone; Tablet (Asus Nexus 7 v2; LG GPad; Amazon Kindle Fire 8.9; Samsung Galaxy Tab; Samsung Nexus 10';
} else if (dev2 == 'X' && rez < 16.6563) {
    dev2 = 'k';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Sony Xperia Z3 smartphone';
} else if (dev2 == 'X' && rez < 16.6667) {
    dev2 = 'l';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Sony Xperia Z3 smartphone, Samsung Galaxy smartphone';
} else if (dev2 == 'X' && rez < 16.7476) {
    dev2 = 'm';
    likelyDeviceNontouch = 'Desktop monitor';
    likelyDeviceTouch =
        'Smartphone (Samsung Galaxy Nexus, Blackberry Z10, LG Nexus 4, LG Optimus G)';
} else if (dev2 == 'X' && rez < 17.0667) {
    dev2 = 'n';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Motorola Nexus 6 smartphone';
} else if (dev2 == 'X' && rez < 17.75) {
    dev2 = 'o';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch =
        'Tablet (Amazon Kindle Fire, Blackberry Playbook, Samsung Galaxy Tab)';
} else if (dev2 == 'X' && rez < 17.7778) {
    dev2 = 'p';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Apple iPhone 5; Apple iPod Touch';
} else if (dev2 == 'X' && rez < 17.7865) {
    dev2 = 'q';
    likelyDeviceNontouch = 'Monitor/Pre-2012 laptop';
    likelyDeviceTouch =
        'Smartphone (iPhone 6 Plus/6S Plus; Microsoft Lumia; Blackberry Z30; HTC Evo 3D; HTC One; Lenovo K900; LG G3/G4/G5; LG Nexus 5; Samsung Galaxy S3/S4/S4 mini/S5/S6/S7/S7 Edge; Samsung Galaxy Note 2/3/4; Tablet (Microsoft Surface Pro/Pro 2';
} else if (dev2 == 'X' && rez < 17.7867) {
    dev2 = 'r';
    likelyDeviceNontouch = 'Monitor/Laptop';
    likelyDeviceTouch = 'x';
} else if (dev2 == 'X' && rez < 17.8205) {
    dev2 = 's';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Apple iPhone 6/6S/7';
} else if (dev2 == 'X' && rez < 18.963) {
    dev2 = 't';
    likelyDeviceNontouch = 'x';
    likelyDeviceTouch = 'Blackberry Leap smartphone';
} else if (dev2 == 'X' && rez <= 18.963) {
    dev2 = 'u';
    likelyDeviceNontouch = 'Monitor/Laptop';
    likelyDeviceTouch = 'x';
} else if (dev2 == 'X' && rez > 18.963) {
    dev2 = 'v';
    likelyDeviceNontouch = 'Unknown device';
    likelyDeviceTouch = 'Unknown device';
}
if (isTouchDevice > 0) {
    dev2 = dev2.toUpperCase();
    likelyDeviceDisplay = likelyDeviceTouch;
} else {
    dev2 = dev2.toLowerCase();
    likelyDeviceDisplay = likelyDeviceNontouch;
}
if (likelyDeviceDisplay == 'x') {
    likelyDeviceDisplay = 'Unknown';
} else {
    likelyDeviceDisplay;
}
if (likelyDeviceDisplay == 'Unknown' && brow == 'E') {
    likelyDeviceDisplay =
        'Using Microsoft Edge (default Windows 10 browser; replaced IE in July 2015)<br>Note: Touchscreen defaults to false for devices using Edge browser';
} else {
    likelyDeviceDisplay;
}
if (brow == 'I') {
    var lang = navigator.systemLanguage;
} else {
    var lang = navigator.language;
}
var langlen = String(lang).length;
var last2 = String(lang).substring(langlen, langlen - 2);
var marketingID = navigator.userAgent;
marketingID += screen.width;
marketingID += screen.height;
marketingID += navigator.platform;
marketingID += navigator.cpuClass;
marketingID += pluginstr;
var marketingIDhash = marketingID.hashCode();
var marketingIDstr = marketingIDhash.toString();
var marketingIDstr12 = marketingIDstr.substring(0, 12);
while (marketingIDstr12.length < 12) {
    marketingIDstr12 = '0' + marketingIDstr12;
}
var marketingIDRemoveNeg = marketingIDstr12.replace(/-/g, 'A');
var marketingIDpart1 = brow + tzoffsetstr + last2.toUpperCase() + dev2 + oldNew;
var marketID = marketingIDpart1 + '|' + shoppingID + '|' + marketingIDRemoveNeg;

export function getMarketID() {
    return marketID;
}

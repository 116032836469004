import axios from 'axios';

export const GET_BILL_HISTORY = 'GET_BILL_HISTORY';
export const GET_BILL_HISTORY_FULFILLED = `${GET_BILL_HISTORY}_FULFILLED`;
export const GET_BILL_HISTORY_REJECTED = `${GET_BILL_HISTORY}_REJECTED`;

export function getBillHistory(billingAccountId: string, noOfMonths: number) {
  const params = new URLSearchParams();
  params.append('noOfMonths', `${noOfMonths}`);

  return {
    type: GET_BILL_HISTORY,
    payload: axios.get(
      `${process.env.REACT_APP_BILL_HISTORY_SERVICE_ENDPOINT}/${billingAccountId}`,
      { params }
    )
  };
}

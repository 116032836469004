import axios from 'axios';
import { BillingAccount } from '../reducers/billingAccountsReducer';

export const SELECT_BILLING_ACCOUNT = 'SELECT_BILLING_ACCOUNT';
export const SELECT_BILLING_ACCOUNT_PENDING = `${SELECT_BILLING_ACCOUNT}_PENDING`;
export const SELECT_BILLING_ACCOUNT_FULFILLED = `${SELECT_BILLING_ACCOUNT}_FULFILLED`;
export const SELECT_BILLING_ACCOUNT_REJECTED = `${SELECT_BILLING_ACCOUNT}_REJECTED`;

export function selectBillingAccount(request: BillingAccount): any {
  return {
    type: SELECT_BILLING_ACCOUNT,
    payload: axios.post(
      `${process.env.REACT_APP_SELECT_BILLING_ACCOUNT_SERVICE_ENDPOINT}`,
      { ...request, detailedInformation: true }
    ),
    meta: request
  };
}

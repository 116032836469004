import { GET_BILL_HISTORY_FULFILLED } from '../actions/getBillHistory';
import { SELECT_BILLING_ACCOUNT_FULFILLED } from '../actions/selectBillingAccount';
import { BillType } from '../components/BillHistory';

interface BillResponseType {
  billId: number;
  startDate: string;
  endDate: string;
  totalAmount: number;
  available: boolean;
  dueDate: string;
}

export default function paymentHistoryReducer(
  state: BillType[] | null = null,
  action: any
): BillType[] | null {
  switch (action.type) {
    case GET_BILL_HISTORY_FULFILLED: {
      const { data } = action.payload;
      if (!data.results) {
        return null;
      }
      const billHistory: BillType[] = data.results.billInfo.map(
        (bill: BillResponseType) => {
          return {
            fromDate: bill.startDate,
            thruDate: bill.endDate,
            billId: bill.billId,
            dueDate: bill.dueDate,
            amount: bill.totalAmount
          };
        }
      );
      return billHistory;
    }
    case SELECT_BILLING_ACCOUNT_FULFILLED:
      return null;
    default:
      return state;
  }
}

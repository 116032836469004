export interface SelectedPaymentAccount {
  type: string;
  financialAccountId: string;
  billingArrangementId: string;
  billingArrangementName: string;
  amount: number;
}

export const SELECT_MULTIPLE_PAYMENT_ACCOUNTS =
  'SELECT_MULTIPLE_PAYMENT_ACCOUNTS';

export function selectMultiplePaymentAccounts(
  selectedPaymentAccounts: SelectedPaymentAccount[]
): any {
  return {
    type: SELECT_MULTIPLE_PAYMENT_ACCOUNTS,
    payload: selectedPaymentAccounts
  };
}

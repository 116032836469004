import { GET_USER_NAME_FULFILLED } from '../actions/getUsername';
import { SAVE_USER_NAME_FULFILLED } from '../actions/saveUsername';

export default function userNameReducer(
  state: null | string = null,
  action: any
): null | string {
  switch (action.type) {
    case GET_USER_NAME_FULFILLED: {
      const { data } = action.payload;
      if (data.statusCode === '1004') {
        return null;
      }
      return data.results.userName;
    }
    case SAVE_USER_NAME_FULFILLED: {
      const { data } = action.payload;
      return data.results.userName;
    }
    default:
      return state;
  }
}

import { SelectedPaymentAccount } from './selectMultiplePaymentAccounts';

export const SELECT_PAYMENT_AMOUNT = 'SELECT_PAYMENT_AMOUNT';

export function selectPaymentAmount(
  selectedPaymentAmount: SelectedPaymentAccount[]
): any {
  return {
    type: SELECT_PAYMENT_AMOUNT,
    payload: selectedPaymentAmount
  };
}

import axios from 'axios';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_FULFILLED = `${SIGN_OUT}_FULFILLED`;
export const SIGN_OUT_REJECTED = `${SIGN_OUT}_REJECTED`;

export default function signOut(signOutType?: string): any {
  return {
    type: SIGN_OUT,
    payload: axios.post(`${process.env.REACT_APP_SIGNOUT_SERVICE_ENDPOINT}`),
    meta: { signOutType }
  };
}

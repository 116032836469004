import axios from 'axios';

export const GET_SECURITY_CODE = 'GET_SECURITY_CODE';
export const GET_SECURITY_CODE_PENDING = `${GET_SECURITY_CODE}_PENDING`;
export const GET_SECURITY_CODE_REJECTED = `${GET_SECURITY_CODE}_REJECTED`;
export const GET_SECURITY_CODE_FULFILLED = `${GET_SECURITY_CODE}_FULFILLED`;

export function getSecurityCode(): any {
  return {
    type: GET_SECURITY_CODE,
    payload: axios.get(
      `${process.env.REACT_APP_GET_SECURITY_CODE_SERVICE_ENDPOINT}`
    )
  };
}

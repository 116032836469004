import axios from 'axios';

export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_FULFILLED = `${GET_CONFIG}_FULFILLED`;

export default function getConfig() {
  return {
    type: GET_CONFIG,
    payload: axios.get(`${process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT}`)
  };
}
